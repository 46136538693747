@import "../../../../assets/stylesheet/global/varibale";

.login-container {
  .inner {
    .description {
      text-align: center;
      font-size: 18px;
    }
  }

  .login-tab {
    display: flex;
    justify-content: space-between;

    li {
      border: 1px solid $grey;
      cursor: pointer;
      font-size: 13px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      width: calc(100%/2);

      .icon {
        line-height: 0;
        margin-right: 10px;
        vertical-align: middle;
      }

      &.active {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $white;
        position: relative;
        font-weight: bold;

        &:after {
          width: 0;
          height: 0;
          border-top: 10px inset transparent;
          border-bottom: 10px inset transparent;
          border-left: 10px solid $primary-color;
          position: absolute;
          content: "";
          bottom: -13px;
          left: 50%;
          transform: translateX(-50%) rotate(90deg);
        }
      }

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  .b2b-modal-content {
    form {
      text-align: center;

      .otp-pwd {
        border: 1px solid $grey;
        border-radius: 5px;
        font-size: 20px;
        width: 120px;
        padding-right: 0;
        padding-left: 0;
        text-align: center;
        letter-spacing: 4px;
        font-family: VoltePlay_Medium, sans-serif;
        height: 35px;
        margin-top: 0px;


        &::-webkit-input-placeholder {
          font-size: 20px;
        }
      
      }
    }
  }
}

.b2b-modal-content {
  &.multipleChainIdList {
    padding: 0;

    .b2b-modal-header {
      border-bottom: 1px solid $grey;
      padding: 10px;
    }
  }
}

.chainId-header{
  background-color: $primary-color;
  color: $white;
  padding: 10px;
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  span{
    &:nth-child(1) {
      width: 35%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:last-child {
      width: 45%;
    }
  }
}

.chain-list-container {
  border-radius: 2px;
  margin-bottom: 20px;
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;

  .chainId-list {
    text-align: left;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
    &:last-child{
      border-bottom: none;
      margin-bottom: 0;
    }
    .radio-element {
      width: 35%;
      text-align: left;
      .radio-text {
        font-family: 'Roboto Mono', monospace;
      }
      ~ span {
        font-family: 'Roboto Mono', monospace;
        &:nth-child(2){
          width: 20%;
        }
        &:last-child {
          width: 45%
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #070a7b;
  }
}