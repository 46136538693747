@import "../../../assets/stylesheet/global/varibale";
@import "../../../assets/stylesheet/global/mixin";

.connection-list {
  .b2b-modal-content {
    max-width: 600px;
  }
}

.connection-accordion {
  background: $white;
  border: 1px solid $grey;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 20px;

  .default-content {
    display: flex;
    position: relative;
    width: 100%;

    .image {
      border-radius: 5.5px;
      height: 110px;
      width: 110px;
      margin-right: 20px;
    }

    .details {
      display: inline-block;

      .title {
        color: $light-font;
        font-family: VoltePlay_Medium, sans-serif;
        font-size: 18px;
        padding-top: 5px;
      }

      .name {
        // background-image: linear-gradient(to right, #ea1b2d, #a63283), linear-gradient(to bottom, #343940, #343940);
        color: #6B00DD ;
        font-family: VoltePlay_Medium, sans-serif;
        font-size: 27px;
        // -webkit-background-clip: text;
        // background-clip: text;
        // -webkit-text-fill-color: transparent;

        span{
          font-size: 18px;
          margin-left: 15px;
          border: 1px solid $grey;
          border-radius: 5px;
          padding: 1px 10px;
          cursor: default;
        }

        @include break-point(ie) {
          @include ie-text-gradient;
        }
      }

      .id-title {
        color: $light-font;
        font-size: 16px;
      }

      .id-info {
        align-items: center;
        display: inline-flex;
        font-family: VoltePlay_Medium, sans-serif;
        font-size: 18px;

        .refresh {
          color: $primary-color;
          cursor: pointer;
          font-family: VoltePlay_Medium, sans-serif;
          font-size: 14px;
          position: relative;

          &-icon {
            line-height: 0;
            margin-right: 5px;
            vertical-align: sub;
          }

          &-prohibited {
            color: $grey;
            pointer-events: none;
          }

          &:after {
            content: '';
            position: absolute;
            width: 100%; height: 100%;
            top: 0; left: 0;
          }
        }
      }
    }

    .show-hide-action {
      background-color: #eceff7;
      border: 1px solid $grey;
      border-radius: 50%;
      cursor: pointer;
      height: 32px;
      position: absolute;
      top: 42%;
      right: 0;
      text-align: center;
      transform: translateY(-50%);
      width: 32px;

      .icon {
        line-height: 32px;
      }
    }
    
    &.show {
      .show-hide-action {
        cursor: pointer;

        .icon {
          margin-top: -1px;
          transform: rotate(180deg);
        }
      }

      +.action-content {
        display: flex;
      }
    }

    +.action-content {
      align-items: center;
      border-top: 1px solid $grey;
      display: none;
      margin-top: 20px;

      .action-type {
        cursor: pointer;
        font-family: VoltePlay_Medium, sans-serif;
        margin-right: 20px;
        position: relative;

        .action-icon {
          line-height: 0;
          margin-right: 6px;
          vertical-align: sub;
        }

        &:after {
          color: $grey;
          content: '|';
          font-size: 20px;
          position: absolute;
          top: -6px;
          right: -10px;
        }

        &:nth-last-of-type(1) {
          &:after {
            content: '';
          }
        }

        &.edit {
          color: $primary-color;
          text-decoration: underline;
        }

        a {
          color: $font-default-color;
        }
      }

      .suspend-text {
        line-height: 15px;
        margin-left: auto;
        width: 230px;

        .link {
          font-family: VoltePlay_Medium, sans-serif;

          a {
            color: $primary-color;
          }
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 80px;
  }
}