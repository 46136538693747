@import "../../../../assets/stylesheet/global/varibale";

table {
  font-size: 15px;
  line-height: 1.4;
thead{
    border-bottom: 1px solid #d3d5da;
    line-height: 35px;
}
tbody > tr{
    border-bottom: 1px solid #d3d5da;
    // line-height: 35px;
}
}