@import "../../assets/stylesheet/global/varibale";
@import "../../assets/stylesheet/global/mixin";

.service-request {
  .tab-strip-row {
    // background-color: $white;
    // border-bottom: 1px solid $grey;

    // .list-tab {
      // border-bottom: 0;
      // margin-bottom: 0;
      // padding-right: 30px;
      // padding-left: 30px;
      // width: 52%;
    // }
    ul{
      li{
        width:50%;
        color:$font-default-color;
        // border-bottom:3px solid $light-font;
        text-align:center;
        font-size: 16px;
        cursor: pointer;
        padding: 10px 5px;
        border-left: 1px solid #dbd9d9;
        background: #fbd0e2;
        // margin-left: 2px;
        border-radius: 8px 8px 0 0;
        border-bottom: 1px solid #ccc;
        &:hover {
          background: #e10092;
          color: #fff;
        }
        &.active {
          background: #fff;
          position: relative;
          border: 1px solid #ccc;
          border-bottom: 0;
          color: #444;
        }
      }
    }
  }

  &-form {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px 10px;
    background-color: white;
    .dropdown-control {
      border: 1px solid $grey;
      border-radius: 6px;
      font-size: 16px;
      padding: 10px 20px;
      width: 100%;

      option {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    .description-textarea {
      border-color: $grey;
      border-radius: 6px;
      height: 200px;
      font-size: 18px;
      padding: 20px;
      resize: none;
      width: 100%;

      &::placeholder {
        color: $light-font;
      }
    }

    .button {
      font-size: 24px;
      height: 48px;
      width: 240px;
    }
  }
}
.SRTrack-request-container{
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px 10px;
  background-color: #fff;
}