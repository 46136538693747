$black: #000;
$white: #fff;
$red: #f00;
$grey: #ccc;
$font-default-color: #343940;
$light-font: #67757d;
$primary-color: #E10092;
$wrapper-size: 1290px;
$content-wrapper-size: 1170px;
$secondary-color:#6B00DD;
$font-regular:VoltePlay_Regular, sans-serif;
$font-medium:VoltePlay_Medium, sans-serif;
$font-bold:VoltePlay_Bold, sans-serif;
$font-inr:Indian_Rupee;
$heading-color:#220046;