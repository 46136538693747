@import "../../../../assets/stylesheet/global/varibale";
@import "../../../../assets/stylesheet/global/mixin";

.user-info {
  align-items: center;
  display: inline-flex;
  padding-right: 15px;

  @include break-point(tablet-view) {
    position: absolute;
    right: 40px;
    bottom: 10px;
  }

  .detail {
    cursor: pointer;
    position: relative;

    .greeting {
      color: $light-font;
      font-size: 12px;
      font-weight: 700;
    }

    .name {
      font-family: VoltePlay_Medium, sans-serif;
      font-size: 15px;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #E10092;
      // background-image: linear-gradient(to right, #ea1b2d, #a63283);
      // -webkit-background-clip: text;
      // background-clip: text;
      // -webkit-text-fill-color: transparent;

      @include break-point(ie) {
        @include ie-text-gradient;
      }
    }

    .icon {
      line-height: 0;
      position: absolute;
      top: 22px;
      right: -15px;
    }

    .user-dropdown {
      background-color: $white;
      border-radius: 5px;
      box-shadow: 0 3px 10px 0 rgba(161, 168, 188, .5);
      cursor: default;
      display: none;
      font-family: VoltePlay_Medium, sans-serif;
      font-size: 15px;
      margin-bottom: 0;
      min-height: 60px;
      padding: 20px;
      position: absolute;
      top: 45px;
      right: -25px;
      width: 190px;
      z-index: 99;

      .option {
        align-items: center;
        cursor: pointer;
        display: inline-flex;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .o-icon {
          line-height: 0;
          margin-right: 8px;
          vertical-align: top;
        }

        a {
          color: $font-default-color;

          &:hover {
            color: $primary-color;
          }
        }

        &:hover {
          color: $primary-color;
        }
      }

      &:after {
        content: '';
        border-right: 5px solid transparent;
        border-bottom: 5px solid $white;
        border-left: 5px solid transparent;
        height: 0;
        position: absolute;
        top: -5px;
        right: 10px;
        width: 0;
      }
    }

    &.open {
      .user-dropdown {
        display: inline-block;
      }
    }
  }
}

.user-details {
  // background-color: $white;
  // box-shadow: 0 2px 6px rgba(0, 0, 0, .5);
  font-size: 16px;
  // margin-right: auto;
  // margin-left: auto;
  // max-width: 500px;
  width: 800px;
  margin: 0 auto;

  .title {
    font-family: VoltePlay_Regular, sans-serif;
    font-size: 24px;
  }
  .mid-box{
    .col-sm-4{
      font-family: VoltePlay_Medium, sans-serif;
      max-width: 130px;

    }
  }
}