.password-container{
    margin: auto;
    width: 40%;
    margin-bottom: 45px;
}
.back-button {
    font-size: 18px;
    font-weight: 600;
    color: black;
}
.back-button i {
    position: relative;
    left: 0;
    transition: left 1s linear;
    margin-right: 3px;
}
.back-button:hover i {
    left: -20px;
}
.gradient-text-password{
    display: inline-block;
      color: #E10092;
      font-size: 28px;
      letter-spacing: -1px!important;
      margin-bottom: 0!important;
      padding: 10px 18px;
      text-align: center;
}
