@import "../../assets/stylesheet/global/varibale";
@import "./../../assets/stylesheet/global/mixin";
// @import "./../../assets/stylesheet/bootstrap/scss/transitions";
// @mixin transition($transition...) {
//   @if $enable-transitions {
//     @if length($transition) == 0 {
//       transition: $transition-base;
//     } @else {
//       transition: $transition;
//     }
//   }
// }
// @import "./../../assets/stylesheet/bootstrap/scss/mixins/transition";
// @import "./../../assets/stylesheet/bootstrap/scss/variables";

.b2b-bulk-modify {
  .action-row {
    min-height: 80px;
  }

  .select-pack {
    background: $white;
    border-color: $grey;
    border-radius: 4px;
    cursor: pointer;
    font-family: VoltePlay_Regular, sans-serif;
    height: 48px;
    text-indent: 5px;
    max-width: 240px;
    width: 100%;
    padding: 10px;

    &:focus {
      border: 1px solid $primary-color;
      outline: none;
    }

    @include break-point(ie) {
      max-width: 225px;
    }

    &:disabled {
      background-color: $grey;
      cursor: not-allowed;
    }
  }

  .search-container {
    align-self: unset;
    width: 520px;
  }

  td {
    &:first-child {
      width: 10%;
    }

    &:nth-child(2) {
      width: 25%;
    }
  }

  .modify-pack-table {
    td {
      &:first-child {
        width: 20%;
      }

      &:nth-child(2) {
        width: 20%;
      }
    }
  }
}
.b2b-bulk-modify-revamp {
  .bulk-operation-tabs {
    .tab-wrapper {
      width: 100%;
    }
    .tab {
      width: 50%;
      background: #fbd0e2;
      cursor: pointer;
      padding: 10px;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #ccc;
      border-radius: 10px 10px 0 0;
      margin: 0 5px 0 0;
      line-height: 30px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background: #fff;
      }
    }
    .btn-fill {
      background: #fff;
      border: 1px solid #ccc;
      border-bottom: none;
    }
  }
  & + footer.footer-container {
    margin-bottom: 60px;
  }
  select[name="language"] {
    width: 115px;
  }
  select[name="genre"] {
    width: 82px;
  }
}
.text-center {
  text-align: center;
}
.select-type-radio input {
  margin-right: 7px;
  cursor: pointer;
}
.select-type-radio input:disabled{
  cursor: default;
}
.bulkOperationFooter {
  button.button {
    margin: 0 10px;
    min-width: 140px;
  }
}
.b2b-bulk-status-update {
  .select-date-wrap {
    padding-top: 25px;
    p {
      text-align: center;
      margin-top: 20px;
      font-size: 16px;
    }
    .formdiv {
      margin-top: 38px;
    }
    .lablediv {
      margin: 0 24px !important;
    }
  }
}
