@import "./../global/varibale";

.form-element {
  position: relative;

  .input-element {
    border: 1px solid $grey;
    border-radius: 6px;
    font-family: VoltePlay_Medium sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
    padding: 6px 45px 6px 15px;
    width: 100%;

    &:focus {
      border-color: $primary-color;
    }

    &::placeholder {
      color: $light-font;
      font-family: VoltePlay_Regular, sans-serif;
      font-size: 14px;
    }

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &.big {
      font-size: 20px;
      height: 48px;
      padding-top: 3px;
      padding-left: 55px;

      &::placeholder {
        font-size: 16px;
      }
    }
  }

  .info-icon {
    cursor: pointer;
    line-height: 0;
    position: absolute;
    top: 8px;
    right: 15px;
    vertical-align: middle;

    &:hover {
      svg {
        path {
          fill: #E10092;
        }
      }

      +.tooltip-element {
        display: block;
      }
    }
  }

  // error case

  .error-section {
    display: none;

    .icon {
      line-height: 0;
      position: absolute;
      top: 8px;
      right: 20px;
    }

    .error-message {
      color: #cb2654;
      font-size: 14px;
      line-height: 15px;

      .title {
        color: #cd2551;
        font-family: VoltePlay_Medium sans-serif;
      }
    }
  }

  // success case

  .success-section {
    display: none;

    .icon {
      line-height: 0;
      position: absolute;
      top: 10px;
      right: 20px;
      vertical-align: middle;
    }
  }

  &.error {
    .input-element {
      border-color: $red;
    }

    .error-section {
      display: block;
      margin-bottom: -15px;
    }

    .info-icon {
      display: none;
    }
  }

  &.success {
    .success-section {
      display: block;
    }
  }
}