@import "../../../assets/stylesheet/global/varibale";
@import ".././../../assets/stylesheet/global/mixin";

.success-page{
    padding-top:150px;
    margin-bottom:150px;
    .svg-icon-right{
        width:100px;
        margin:0 auto !important;
        
    }
    .details{
        padding-top:30px;
    }
}