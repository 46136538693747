@import "./../global/varibale";

.radio-element {
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;

  .radio-text {
    font-size: 16px;
  }
  
  &.disabled {
    cursor: default;
  }
}

.radio-element input {
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked {
    ~.radio-text {
      color: #51b684;
      font-family: VoltePlay_Medium, sans-serif;
    }
  }
}

.select-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border: 2px solid $light-font;
  border-radius: 50%;
}

.radio-element input:checked ~ .select-radio {
  background-color: #fff;
  border: 2px solid #51b684;
}

.radio-element.disabled input:disabled ~ .select-radio {
  border: 2px solid $grey;
}

.radio-element .select-radio:after {
  content: "";
  display: none;
  width: 13px;
  height: 13px;
  background: #51b684;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.radio-element input:checked ~ .select-radio:after {
  display: block;
}

.radio-element.disabled .select-radio:after {
  display: block;
  background: $grey;
}
