@import "../../../assets/stylesheet/global/varibale";

.register-container {
  >.title {
    font-size: 18px;
    text-align: center;
  }
}


.register-container {

  .b2b-modal-content {
    form {
      text-align: center;

      .otp-pwd {
        border: 1px solid $grey;
        border-radius: 0;
        font-size: 20px;
        width: 120px;
        padding-right: 0;
        padding-left: 0;
        text-align: center;
        letter-spacing: 4px;
        font-family: VoltePlay_Medium, sans-serif;
        height: 35px;
        margin-top: 0px;

        &::-webkit-input-placeholder {
          font-size: 20px;
        }
      }
    }
  }
}