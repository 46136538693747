@import "../../../assets/stylesheet/global/varibale";
@import "../../../assets/stylesheet/global/mixin";

.header {
  background-color: $white;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .inner {
    display: flex;
    align-items: center;

    @include break-point(tablet-view) {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 10px;
    }
  }

  .logo {
    cursor: pointer;
    // height: 38px;

    @include break-point(tablet-view) {
      height: 30px;
      margin-top: 10px;
      margin-right: 30px;
    }
  }

  .link-btn {
    a {
      color: $white;

      &:hover {
        color: $grey;
      }
    }

    @include break-point(tablet-view) {
      font-size: 14px;
      height: 40px;
      margin-top: 10px;
    }
  }
}

.ml-auto {
  margin-left: auto;
}