@import "../../assets/stylesheet/global/varibale";
@import "../../assets/stylesheet/global/mixin";
@import "./../../../src/assets/stylesheet/global/varibale";


.signup-box {
  border: 1px solid #ddd;
  max-width: 400px; /* Reduce the width for a single column layout */
  margin: 20px auto;
  padding: 20px;
  border-radius: 5px;
  text-align: center; /* Center-align the content */
  margin-top: 68px;
}

.title {
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
}

.err {
  font-size: small;
  color: red;
}

.asterisk {
  font-size: small;
  color: red;
}

.label-left {
  font-family: "VoltePlay-Medium", sans-serif;
  margin-bottom: 5px;
  font-size: 16px;
}



.form-group {
  margin-bottom: 20px; /* Add spacing between form fields */
}

.form-control {
  width: 100%; /* Make the input fields full width */
}
.input-element{
border: 1px solid $grey;
border-radius: 6px;
font-family:  "VoltePlay-Medium",sans-serif;;
font-size: 16px;
margin-bottom: 5px;
padding: 6px 45px 6px 15px;
width: 100%;
}
.label{
  display: block;
}
.js-form-item{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}
   

/* Styles for the background overlay */
.popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styles for the popup container */
.popup-container {
  background: #fff; /* Popup background color */
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  padding: 20px;
  text-align: center;
}

/* Close button for the popup */
.popup-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}


.popup-header {
  font-size: 24px;
  margin-bottom: 10px;
}

.popup-content {
  font-size: 16px;
}

